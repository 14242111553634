.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  background-size: cover;
  background-position: center;
  overflow-y: auto;
}